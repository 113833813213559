import React, {useState, useEffect} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { loanHistory } from '../Request/Histories';
import { Link } from 'react-router-dom';

import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';

const Loans = () => {

    const initialLoan = {
        amount: 0,
        description: '',
        loanType: ''
    }
    const [loanForm, setLoanForm] = useState(initialLoan);
    const onChange = (e) =>
    setLoanForm({ ...loanForm, [e.target.name]: e.target.value });
    const { isLoading: isSendingRequest, mutate: postLoan } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/loan-application`, {
            amount: loanForm.amount,
            description: loanForm.description,
            loanType: loanForm.loanType
          });
        },
        {
          onSuccess: (res) => {            
            setSuccessMessage(res.data);
            triggerSuccess();
          },
          onError: (err) => {    
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );


      let myloanHistory = loanHistory();

      const [pendingloans, setPendingloan] = useState(0);
      const [activeloan, setActiveloan] = useState(0);
      const [completedloan, setcompletedloan] = useState(0);
      const getDate = (date) => new Date(date).toDateString();
  
      const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
      const setCurrentloan = (loan) => {
          localStorage.setItem('adminhistoryloanSelected', JSON.stringify(loan));
          const loanEvent = new Event('adminhistoryloanSelected');
          window.dispatchEvent(loanEvent);
      }
     

      useEffect(() => {
        document.title = 'LOANS';
        myloanHistory = loanHistory();

        let pendingNum = 0;        
        let activeNum = 0;        
        let completedNum = 0;  

        myloanHistory.forEach(loan => {
                
                if (loan.status === 'approved') {
                    activeNum += parseInt(loan.amount);
                }
                if (loan.status === 'completed') {
                    completedNum += parseInt(loan.amount);
                }
                if (loan.status === 'declined') {
                    completedNum += parseInt(loan.amount);
                }
                if (loan.status === 'pending') {
                    pendingNum += parseInt(loan.amount);
                }
                if (loan.status === 'waiting approval') {
                    pendingNum += parseInt(loan.amount);
                }
            
        });
        
        setActiveloan(activeNum);
        setcompletedloan(completedNum);
        setPendingloan(pendingNum);
        
        if(window && window.init_template){
            window.init_template();
        }  
    }, [myloanHistory, activeloan, completedloan, pendingloans]);

  return (
    <div>
        <div className=' justify-content-center' style={{marginTop: "90px"}}>
       

        <div className="card bg-theme shadow-sm " style={{borderRadius: '10px'}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">APPLY FOR LOANS</h1>
                        
                    </div>
                </div>
                
                <div className="card card-style bg-fade-yellow border border-fade-yellow alert alert-dismissible show fade p-0 mb-3">
                    <div className="content my-3">
                        <div className="d-flex">
                            <div className="align-self-center no-click">
                                <i className="bi bi-info-square font-33 color-yellow-dark d-block"></i>
                            </div>
                            <div className="align-self-center no-click">
                                <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                Note: kindly note that only users who have participated in the <strong>Spot Futures Arbitrage Bot Trade</strong> are eligible to apply.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <div className="content mt-0">
                    <div className="form-custom form-label form-icon">            
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>category</i>
                        <select onChange={onChange} name="loanType" className="form-select bg-theme color-theme rounded-xs" id="loanType" aria-label="choose loan type">
                            <option value="">Select Option </option>
                            <option value="home loan">Home Loan</option>
                            <option value="car loan">Car Loan</option>
                            <option value="others">Others </option>
                        </select>
                        <label htmlFor="loanType" className="form-label-always-active color-highlight font-11">Choose Loan Type </label>
                    </div>
                    
                    <div className="pb-3"></div>
                    <div className="form-custom form-label form-icon">
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>money</i>
                        <input onChange={onChange} type="number" name="amount" className="form-control bg-theme color-theme rounded-xs" id="withdrawAmount" placeholder="enter amount"/>
                        <label htmlFor="withdrawAmount" className="form-label-always-active color-highlight font-11">Amount</label>
                        <span className="font-10">( Currency: USD )</span>
                    </div>
                    <div className="pb-2"></div>

                    <div className="pb-3"></div>
                    <div className="form-custom form-label form-icon">
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                        <textarea onChange={onChange} type="text" name="description" className="form-control bg-theme color-theme rounded-xs" id="description" placeholder='loan details'></textarea>
                        <label htmlFor="description" className="form-label-always-active color-highlight font-11">Additional Details</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                </div>
                
                {/* <div className="alert bg-fade-red color-red-dark alert-dismissible rounded-s fade show" role="alert">
                   <i className="bi bi-exclamation-triangle pe-2"></i>
               </div> */}

                <div onClick={postLoan} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
                {isSendingRequest ?<span>Applying...</span>
                :
                <span>Request Loan</span>
                }
                </div>
            </div>
        </div>


        <h2 className='text-center mb-2 mt-4'>
            LOAN HISTORY
        </h2>
        <div className="card shadow-sm" style={{borderRadius: '8px'}}>
            <div className="content mb-0">
                <div className="tabs tabs-pill" id="lend-customers">
                    <div className="tab-controls rounded-m p-1 overflow-visible">
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Completed</a>
                    </div>
                    <div className="mt-3"></div>
                    {/* <!-- Tab Bills List --> */}
                    <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!pendingloans > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No pending loan records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myloanHistory.map(loan =>(
                                    (loan.status === 'pending' || loan.status === 'waiting approval') &&
                                    <Link key={loan.id} onClick={()=>setCurrentloan(loan)} data-bs-toggle="offcanvas" data-bs-target="#admin-loan-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{getAmount(loan.amount)}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(loan.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-red-dark">{loan.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{loan.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>

                <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!activeloan > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No active loan records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myloanHistory.map(loan =>(
                                    loan.status === 'approved' &&
                                    <Link key={loan.id} onClick={()=>setCurrentloan(loan)} data-bs-toggle="offcanvas" data-bs-target="#admin-loan-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{getAmount(loan.amount)}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(loan.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-green-dark">{loan.user.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">{loan.user.email}</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>
                
                {/* <!-- Tab Custom Payments--> */}
                <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
                    <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                    <div className="content mt-0 mb-0">
                        {!completedloan > 0 ?
                        <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                            <div className="content my-3">
                                <div className="d-flex">
                                    <div className="align-self-center no-click">
                                        <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                    </div>
                                    <div className="align-self-center no-click">
                                        <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                            No completed loans record avaialable
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                            myloanHistory.map(loan =>(
                                (loan.status === 'completed' || loan.status === 'declined')  &&
                                <Link key={loan.id} onClick={()=>setCurrentloan(loan)} data-bs-toggle="offcanvas" data-bs-target="#admin-loan-history-menu"  to="#" className="d-flex pb-3">
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                    </div>
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1">{getAmount(loan.amount)}</h5>
                                        <p className="mb-0 font-11 opacity-50">{getDate(loan.created_at)}</p>
                                    </div>
                                    <div className="align-self-center ms-auto text-end">
                                        <h4 className="pt-1 mb-n1 color-theme">{loan.user.name}</h4>
                                        <p className="mb-0 font-12 opacity-50">{loan.user.email}</p>
                                    </div>
                                </Link>
                            ))                
                        
                        }
                    </div>                   
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Loans