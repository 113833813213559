import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { TickerTape } from "react-ts-tradingview-widgets";
import Wave from 'react-wavify'

  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);

const Invest = () => {

    let theme = localStorage.getItem('CHANNEL ASSET FINANCE LIMITED-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
    const [currentTheme, setCurrentTheme] = useState(theme);

    const initialPlans = {
        loading: true,
        planList: null
    }
    const [plans, setPlans] = useState(initialPlans);

    const fetchPlans = async() => {
            let plan = localStorage.getItem('plans');
            if (plan != null) {
                plan = JSON.parse(plan);
                setPlans({...plans, loading: false, planList: plan}); 
            }
               
      }
      const setCurrentPlan = (plan) => {
        localStorage.setItem('newPlan', JSON.stringify(plan));
        const planEvent = new Event('newPlan');
        window.dispatchEvent(planEvent);
      }

      useEffect(()=>{
        document.title = 'INVESTMENT';
        fetchPlans();

        window.addEventListener('themeChanged', function (e) {
             let theme = localStorage.getItem('CHANNEL ASSET FINANCE LIMITED-Theme');
             if (theme !== null) {
                 if (theme === 'dark-mode') {
                    setCurrentTheme('dark')
                 }else{
                    setCurrentTheme('light') 
                 }
                
             }             
        }, false);

      }, [])
      const {loading, planList} = plans;
  return (
    <div className='container px-sm-4 px-0' style={{marginTop: '40px'}}>
        {/* <TickerTape colorTheme={currentTheme}></TickerTape> */}
        {loading?
        <div>
            Loading...
        </div>:

        <div>
            <div className="card text-center border border-fade-blue show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex justify-content-center">
                        <div className="">
                            <h2 className="color-theme mb-0 font-500 font-24">
                                OUR TRADING PLANS
                            </h2>
                            <p>
                                Choose any plan below to get started
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                {planList.map(plan => (
                  <div className="col col-md-4" key={plan.id} >
                    <div  className="text-center mb-4 p-1 py-4 card border bg-theme shadow-sm color-theme" style={{ boxShadow: 'rgba(255, 255, 255) 0px 1px 5px 0px', borderRadius: "15px"}}>
                    <Wave fill='#4ad385'
                          paused={false}
                          options={{
                            height: 20,
                            amplitude: 30,
                            speed: 0.2,
                            points: 3
                          }}
                    />
                    <h2 style={{transform: 'translate(0px, -160px)', color: '#00923f'}}>{plan.name}</h2>
                    <h2 className="" style={{transform: 'translate(0px, -120px)', color: '#f8fbf9'}}>{plan.description}</h2>
                      <div className="card-body" >
                        <div className="d-flex p-2" style={{marginTop: '-70px', boxShadow: 'rgba(255, 255, 255) 0px 1px 2px 0px', borderRadius: '8px'}}>
                          <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs">
                            <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
                            </span>
                          </div>
                          <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>Min Amount:</h5>
                          </div>
                          <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>{getAmount(plan.min_amount)}</h4>
                          </div>
                        </div>

                        <div className="d-flex p-2" style={{marginTop: '5px', boxShadow: 'rgba(255, 255, 255) 0px 1px 2px 0px', borderRadius: '8px'}}>
                          <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs">
                            <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
                            </span>
                          </div>
                          <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>Max Amount:</h5>
                          </div>
                          <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>{`${getAmount(plan.max_amount) === getAmount(1000000000)? 'unlimited': getAmount(plan.max_amount)}`}</h4>
                          </div>
                        </div>

                        <div className="d-flex p-2" style={{marginTop: '5px', boxShadow: 'rgba(255, 255, 255) 0px 1px 2px 0px', borderRadius: '8px'}}>
                          <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs">
                            <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
                            </span>
                          </div>
                          <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>Ref Bonus:</h5>
                          </div>
                          <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>{plan.ref_bonus}%</h4>
                          </div>
                        </div>

                        <div className="d-flex p-2" style={{marginTop: '5px', boxShadow: 'rgba(255, 255, 255) 0px 1px 2px 0px', borderRadius: '8px'}}>
                          <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs">
                            <span className="check"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1659868367/check.png" alt="user" /></span>
                            </span>
                          </div>
                          <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>Durations:</h5>
                          </div>
                          <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-1 color-theme" style={{color: '#a6aba8'}}>{plan.durations_copy}</h4>
                          </div>
                        </div>

                      </div>
                      <div className="">
                        <Link onClick={()=>setCurrentPlan(plan)} data-bs-toggle="offcanvas" data-bs-target="#menu-investment" to="" className="btn btn-primary bg-theme text-success btn-sm " style={{boxShadow: 'rgba(255, 255, 255) 0px 1px 5px 0px'}}>Trade now</Link>
                      </div>
                    </div>
                  </div>
                ))}              
            </div>
        </div>
        }
    </div>
  )
}

export default Invest